import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import styled from "@emotion/styled"
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

/* Product Post Container Styles */
const ProductPostContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;
  max-width: 85%;
  margin: 0 auto;
  margin-top: 10rem;
  margin-bottom: 5rem;

  @media (max-width: 1024px) {
    width: 95%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
`

/* Image Container Styles */
const ProductImageContainer = styled.div`
  width: 35%;
  border: 2px solid black;
  margin-right: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
          align-items: center;

  @media (max-width: 1024px) {
    width: 50%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
  }
  @media (max-width: 425px) {
    width: 90%;
    margin: 0 auto;
  }
`


/* Product Content Syles */

const ProductTextContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  width: 65%;

  @media (max-width: 1024px) {
    width: 90%;
    margin-top: 3rem;
  }
  @media (max-width: 425px) {
    font-size: 2.2rem;
    margin-top: 3rem;
    width: 93%;
  }
`


const ProductPostTitle = styled.h1`
  font-size: 2.5rem;
  margin: 0;
  text-align: center;
  font-weight: bold;

  @media (max-width: 425px) {
    font-size: 2.1rem;
  }
`

const ProductContentContainer = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
          flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.7rem;
  margin: 0 auto;
  margin-top: 2rem;

  @media (max-width: 425px) {
    margin-bottom: 2rem;
  }
`

const ProductButton = styled.a`
  width: 200px;
  color: #ededed;
  background-color: black;
  padding: 0.5rem;
  padding-top: 1rem;
  font-style: italic;
  font-weight: bold;
  letter-spacing: 0.05rem;
  font-size: 1.25rem;
  -webkit-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;

  &:hover {
    background-color: #ededed;
    border: 1px solid black;
    color: black;
    -webkit-transition: background-color 0.5s ease;
    -o-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
  }
`


class ProductPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const ProductTitle = this.props.data.markdownRemark.frontmatter.title
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <ProductPostContainer>
          <ProductImageContainer>
            <Carousel>
              <div>
                    <img src={post.frontmatter.image1} alt={ProductTitle} />
                </div>
                <div>
                    <img src={post.frontmatter.image2} alt={ProductTitle} />
                </div>
            </Carousel>
          </ProductImageContainer>
          <ProductTextContainer>
            <ProductPostTitle>{post.frontmatter.title}</ProductPostTitle>
            <ProductContentContainer dangerouslySetInnerHTML={{ __html: post.html }} />
            <ProductButton href={post.frontmatter.url}>{post.frontmatter.button}</ProductButton>
          </ProductTextContainer> 
        </ProductPostContainer>
      </Layout>
    )
  }
}

export default ProductPostTemplate

export const pageQuery = graphql`
  query ProductPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(
      fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        image1
        image2
        button
        url
      }
    }
  }
`